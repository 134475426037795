// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__OOMYT {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Gotham;
  font-size: 18px;
  font-weight: 500;
  line-height: 17.23px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  padding: 15px;
  border-radius: 8px;
}

.Button_primary__8Xpxy {
  background: var(--purple);
  border: none;
  color: var(--primaryWhite);
}

.Button_secondary__GBY7J {
  border: 1px solid var(--purple);
  color: var(--purple);
  background: transparent;
}

.Button_primary__8Xpxy:disabled,
.Button_tertiary__n99Df:disabled {
  background-color: var(--placeholderPrimary);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .Button_button__OOMYT {
    font-size: 14px;
    line-height: 23px;
    padding: 8px;
  }
}

@media screen and (max-width: 767px) {
  .Button_button__OOMYT {
    font-size: 14px;
    line-height: 23px;
    padding: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Button/Button.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,gCAAgC;EAChC,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;;EAEE,2CAA2C;AAC7C;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,YAAY;EACd;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,YAAY;EACd;AACF","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  font-family: Gotham;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 17.23px;\n  text-align: center;\n  width: 100%;\n  cursor: pointer;\n  white-space: nowrap;\n  transition: all 0.2s ease-in-out;\n  padding: 15px;\n  border-radius: 8px;\n}\n\n.primary {\n  background: var(--purple);\n  border: none;\n  color: var(--primaryWhite);\n}\n\n.secondary {\n  border: 1px solid var(--purple);\n  color: var(--purple);\n  background: transparent;\n}\n\n.primary:disabled,\n.tertiary:disabled {\n  background-color: var(--placeholderPrimary);\n}\n\n@media screen and (min-width: 767px) and (max-width: 1023px) {\n  .button {\n    font-size: 14px;\n    line-height: 23px;\n    padding: 8px;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .button {\n    font-size: 14px;\n    line-height: 23px;\n    padding: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__OOMYT`,
	"primary": `Button_primary__8Xpxy`,
	"secondary": `Button_secondary__GBY7J`,
	"tertiary": `Button_tertiary__n99Df`
};
export default ___CSS_LOADER_EXPORT___;
