import React, { lazy, Suspense } from "react";
import SignIn from "../Containers/SignIn/SignIn";
import { routePaths } from "./routePaths";
import Loader from "../Components/Loader/Loader";

const TresuryProducts = lazy(
  () => import("../Containers/TresuryProducts/TresuryProducts")
);
const Purchases = lazy(() => import("../Containers/Purchases/Purchases"));

type routesType = {
  route: string;
  element: React.ReactNode;
  properties: any[] | null;
  name: string;
}[];

export const routes: routesType = [
  {
    route: routePaths.SIGN_IN,
    element: <SignIn />,
    properties: null,
    name: "Sign In",
  },

  {
    route: routePaths.TRESURY_PRODUCTS,
    element: (
      <Suspense fallback={<Loader />}>
        <TresuryProducts />
      </Suspense>
    ),
    properties: ["isProtected", "isSideNavRoute"],
    name: "Treasury Proucts",
  },

  {
    route: routePaths.PURCHASES,
    element: (
      <Suspense fallback={<Loader />}>
        {" "}
        <Purchases />
      </Suspense>
    ),
    properties: ["isProtected", "isSideNavRoute"],
    name: "Purchases",
  },
];
