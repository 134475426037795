import { createContext, useContext, useState } from "react";
import { purchaseProductDataType, requestType } from "../Utilities/types";
import { requestHandler } from "../HelperFunctions/requestHandler";
import { AuthContext } from "./AuthContext";
import { generateUid } from "../HelperFunctions/generateUid";

type TreasuryContextValuesTypes = {
  getProducts: (
    pageNumber: number,
    startDate?: string,
    endDate?: string,
    additionalParams?: { [key: string]: string | number }
  ) => void;
  requestState: requestType;
  productsState: requestType;
  purchaseProducts: (id: string, data: purchaseProductDataType) => void;
  getPurchases: (
    pageNumber: number,
    startDate?: string,
    endDate?: string,
    additionalParams?: { [key: string]: string | number }
  ) => void;
  purchasesState: requestType;
  getAcountDetails: (accountNumber: string) => void;
  accountInfoState: requestType;
};

type TreasuryContextProviderTypes = {
  children: React.ReactNode;
};

export const TreasuryContext = createContext({} as TreasuryContextValuesTypes);

const TreasuryContextProvider = ({
  children,
}: TreasuryContextProviderTypes) => {
  // States
  const [requestState, setRequestState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [productsState, setProductsState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [purchasesState, setPurchasesState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [accountInfoState, setAccountInfoState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const { errorFlowFunction } = useContext(AuthContext);

  //   Requests
  const getProducts = (
    pageNumber = 1,
    startDate?: string,
    endDate?: string,
    additionalParams: { [key: string]: string | number } = {}
  ) => {
    const basePath = `/api/v1/Product/get-products`;

    const queryParams = new URLSearchParams();

    queryParams.append("PageNumber", pageNumber.toString());

    if (startDate && endDate) {
      queryParams.append("DateFilter.StartDate", startDate);
      queryParams.append("DateFilter.EndDate", endDate);
    }

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        queryParams.append(key, value?.toString());
      }
    });

    const url = `${basePath}?${queryParams.toString()}`;

    requestHandler({
      method: "GET",
      url,
      state: productsState,
      setState: setProductsState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const purchaseProducts = (id: string, data: purchaseProductDataType) => {
    requestHandler({
      method: "POST",
      data: {
        accountNumber: data?.accountNumber,
        volume: Number(data?.volume),
        requestId: generateUid(),
      },
      url: `/api/v1/Subscription/subscribe/${id}`,
      state: requestState,
      setState: setRequestState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      requestCleanup: true,
      id: "purchase-product",
    });
  };

  const getPurchases = (
    pageNumber = 1,
    startDate?: string,
    endDate?: string,
    additionalParams: { [key: string]: string | number } = {}
  ) => {
    const basePath = `/api/v1/Subscription/subscriptions`;

    const queryParams = new URLSearchParams();

    queryParams.append("PageNumber", pageNumber.toString());

    if (startDate && endDate) {
      queryParams.append("DateRange.StartDate", startDate);
      queryParams.append("DateRange.EndDate", endDate);
    }

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        queryParams.append(key, value?.toString());
      }
    });

    const url = `${basePath}?${queryParams.toString()}`;

    requestHandler({
      method: "GET",
      url,
      state: purchasesState,
      setState: setPurchasesState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const getAcountDetails = (accountNumber: string) => {
    requestHandler({
      method: "GET",
      url: `https://accountenquiry.uat-fcmb.com/api/AccountInquiry/GetAllAccountsByAccountNo/${accountNumber}`,
      state: accountInfoState,
      setState: setAccountInfoState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  return (
    <TreasuryContext.Provider
      value={{
        getProducts,
        requestState,
        productsState,
        purchaseProducts,
        getPurchases,
        purchasesState,
        getAcountDetails,
        accountInfoState,
      }}
    >
      {children}
    </TreasuryContext.Provider>
  );
};

export default TreasuryContextProvider;
