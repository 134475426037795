import { CSSProperties } from "react";
import classes from "./Card.module.css";

type CardProps = {
  children: React.ReactNode;
  className?: CSSProperties | string;
  borderRadius?: string;
};

const Card = ({ children, className, borderRadius }: CardProps) => {
  return (
    <section
      className={`${classes.container} ${className}`}
      style={borderRadius ? { borderRadius: borderRadius } : undefined}
    >
      {children}
    </section>
  );
};

export default Card;
