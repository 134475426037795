// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignIn_container__2e4d\\+ {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.SignIn_formContainer__PjvJU {
  margin-top: 56.5px;
  max-width: 618px;
  padding: 46px 59px 47px 59px;
}

.SignIn_formContainer__PjvJU > h1 {
  font-family: Gotham;
  font-size: 24px;
  font-weight: 500;
  line-height: 22.97px;
  letter-spacing: 0.02em;
  color: var(--dark-purple);
  margin-bottom: 40px;
  text-align: center;
}

.SignIn_rememberMe__g-YNr {
  display: flex;
  align-items: center;
  gap: 30px;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 500;
  line-height: 13.4px;
  color: var(--placeholder-grey);
}

.SignIn_formContainer__PjvJU > form > button {
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/Containers/SignIn/SignIn.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  padding: 40px;\n}\n\n.formContainer {\n  margin-top: 56.5px;\n  max-width: 618px;\n  padding: 46px 59px 47px 59px;\n}\n\n.formContainer > h1 {\n  font-family: Gotham;\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 22.97px;\n  letter-spacing: 0.02em;\n  color: var(--dark-purple);\n  margin-bottom: 40px;\n  text-align: center;\n}\n\n.rememberMe {\n  display: flex;\n  align-items: center;\n  gap: 30px;\n  font-family: Gotham;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 13.4px;\n  color: var(--placeholder-grey);\n}\n\n.formContainer > form > button {\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SignIn_container__2e4d+`,
	"formContainer": `SignIn_formContainer__PjvJU`,
	"rememberMe": `SignIn_rememberMe__g-YNr`
};
export default ___CSS_LOADER_EXPORT___;
