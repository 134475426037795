import { generateHeader } from "../Utilities/generateHeaders";
import { config } from "../Config";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: config.base_url,
  headers: {
    "X-Frame-Options": "DENY",
    "X-Content-Type-Options": "nosniff",
    "Cross-Origin-Opener-Policy": "same-origin",
    "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
  },
});

axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    if (!navigator.onLine) {
      throw new Error("Please check your Internet Connection");
    }

    const headers = generateHeader();
    axiosConfig.headers["x-token"] = headers["x-token"];
    axiosConfig.headers["Ocp-Apim-Subscription-Key"] =
      headers["Ocp-Apim-Subscription-Key"];
    axiosConfig.headers["Ocp-Apim-Trace"] = true;
    axiosConfig.headers.UTCTimestamp = headers.UTCTimestamp;
    axiosConfig.headers.Client_ID = headers.Client_ID;

    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data.responseCode === "00") {
      return response;
    } else {
      throw new Error(response?.data?.error?.message);
    }

    // return response;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

export default axiosInstance;
