import { createContext, useEffect, useState } from "react";
import { requestHandler } from "../HelperFunctions/requestHandler";
import { requestType, userAuthType } from "../Utilities/types";
import { sessionStorageHandler } from "../HelperFunctions/sessionStorageHandler";
import { useToast } from "./ToastContext";
import { useLocation, useNavigate } from "react-router-dom";
import { routePaths } from "../Utilities/routePaths";
import {
  SESSION_STORAGE_AUTH_KEY,
  SESSION_STORAGE_AUTH_REFRESH_KEY,
} from "../Utilities/constants";

type AuthContextValuesTypes = {
  signIn: (data: userAuthType) => void;
  requestState: requestType;
  logout: () => void;
  errorFlowFunction: (err: any) => void;
  user: requestType;
  logoutUser: () => void;
};

type AuthContextProviderTypes = {
  children: React.ReactNode;
};

export const AuthContext = createContext({} as AuthContextValuesTypes);

const AuthContextProvider = ({ children }: AuthContextProviderTypes) => {
  // States
  const [requestState, setRequestState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [user, setUser] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  // Router
  const location = useLocation();

  //   Utils
  const { showToast } = useToast();
  const accessToken = sessionStorageHandler(
    "get",
    SESSION_STORAGE_AUTH_KEY as string
  );
  const refreshToken = sessionStorageHandler(
    "get",
    SESSION_STORAGE_AUTH_REFRESH_KEY as string
  );
  const redirectedRoute = location.state || routePaths?.TRESURY_PRODUCTS;

  const errorFlowFunction = (err: any) => {
    if (err?.response?.status === 401) {
      logout();
      showToast("Unauthorized, Please sign in again", "error");
      return;
    }

    showToast(
      err?.response?.data?.error?.message || err?.response?.data?.description,
      "error"
    );
  };

  //   Router
  const navigate = useNavigate();

  // Requests
  const signIn = (data: userAuthType) => {
    requestHandler({
      url: "/api/v1/Authentication/login/RM",
      data,
      method: "POST",
      state: requestState,
      setState: setRequestState,
      successFunction(response) {
        sessionStorageHandler(
          "set",
          SESSION_STORAGE_AUTH_KEY as string,
          response?.data?.data?.token
        );

        sessionStorageHandler(
          "set",
          SESSION_STORAGE_AUTH_REFRESH_KEY as string,
          response?.data?.data?.refreshToken
        );

        navigate(redirectedRoute);

        getUser();
      },
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const getUser = () => {
    requestHandler({
      url: "/api/v1/Authentication/user-profile",
      method: "GET",
      state: user,
      setState: setUser,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const logout = () => {
    sessionStorageHandler("delete", SESSION_STORAGE_AUTH_KEY as string);
    sessionStorageHandler("delete", SESSION_STORAGE_AUTH_REFRESH_KEY as string);

    navigate(routePaths.SIGN_IN);
  };

  const logoutUser = () => {
    requestHandler({
      url: `/api/v1/Authentication/logout/${refreshToken}`,
      method: "POST",
      successFunction() {
        logout();
      },
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  // Effects

  useEffect(() => {
    if (accessToken) {
      getUser();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        requestState,
        logout,
        errorFlowFunction,
        user,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
